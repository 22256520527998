import React from "react";
import Img from "gatsby-image";

const Header = ({ data }) => {
  return (
    <div className="about section" id="About">
      <div className="container">
        <div className="about-main row">
          {/*
        <div className="left col-md-5 col-lg-4 mb-3">
          <Img
            fixed={d.bandNameImg.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="top center"
          />
        </div>
        */}
          <div className="left col-md-8 col-lg-8">
            <div className="about-details">
              <h2 className="sub-position" style={{ fontSize: "20px" }}>
                僕らが一人にならない為の唄。
              </h2>
              {/*<div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html
              }}
            />
            */}
              <div style={{ fontSize: "14px" }}>
                <p>
                  10代の頃は、休みの日も人と会わずに音楽ばかり聞いていた。
                  ロックもポップスも好きだし、ブルースやファンク、R&amp;B、ヒップホップは熱中していた。
                  the pillowsとBump of Chickenは永遠のロックスターだし、B.B.Kingの音色に憧れ、D'angeloのグルーブに身体を動かさせられた。
                  クラシックも好きだし、ジャズも少しなら。オルタナやシューゲイザーなんかも大好物だ。
                </p>
                <p>
                  中学生の頃、父に渡されたリチャード・バックのイリュージョンを読んで以来、本の虫だった。
                  昼食を一緒に食べようと友達に誘われても、断って本を読んでいたのは、さすがによくなかったと思う。
                  リチャード・バックとヘルマン・ヘッセが大好きだった。
                  BLANKEY JET CITYのベンジーや、The Smithsのモリッシーの美しい詩世界に憧れた。未だにそんな詩は書けないけれど。
                </p>
                <p>
                  器が水によって音色を変えるように、複雑な心に今日までを注いだような音を奏でたい。
                  きっと僕らのための音になるから。
                </p>
                <p>
                  水が器に沿って形作られるように、透明な心に今日までを注いだような言葉を紡ぎたい。
                  きっと僕らのための言葉になるから。
                </p>
                <p>
                  僕らがこれまで過ごした日々と、僕らがこれから奏でる音が、同時に存在していたような唄を歌いたい。
                  きっと僕らのための唄になるから。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
