import React, { Component } from "react";
import Img from "gatsby-image";

const subData = [
  // "Trying to reach you in the wind.",
  // "Why are you on your own in the wind.",
  // "Love it as you want in the wind.",
  // "Shout anything you want to the wind.",
  // "Lost yourself in the cool damp wind.",
  // "Fly u into the wind. In other words..",
]

export default class Banner extends Component {

  render() {
    const { data } = this.props;

    const subDataIdx = Math.floor( Math.random() * subData.length )

    return (
      <div className="banner">
        <Img
          fixed={data.bannerImage.fluid}
          objectFit="cover"
          style={{
            height: "60vh"
          }}
          imgStyle={{
            objectPosition: "60% 50%",
            height:"calc(100% - 85px)",
            marginTop: "85px"
          }}
        />
        <div className="container">
          <div className="banner-details">
            <span>kaze no naka</span>
            <h1>風の中</h1>
            <ul className="sub-data">
              <li key="1">{subData[subDataIdx]}</li>
            </ul>
            <ul className="social">
              <li>
                <a
                  className="fab fa-twitter"
                  href="https://twitter.com/kaze_no_naka_"
                  target="_blank"
                  rel="noopener noreferrer"
                ></a>
              </li>
              <li key="2">
                <a
                className="fab fa-youtube"
                href="https://www.youtube.com/channel/UCteOxcnmHHLEmbCfYAupQew"
                target="_blank"
                rel="noopener noreferrer"
                />
              </li>
              <li key="3">
                <a
                className="fab fa-apple"
                href="https://music.apple.com/jp/artist/%E9%A2%A8%E3%81%AE%E4%B8%AD/1481928376"
                target="_blank"
                rel="noopener noreferrer"
                />
              </li>
              <li key="4">
                <a
                className="fab fa-spotify"
                href="https://open.spotify.com/artist/0LqA04wbvcYttyyTsEUDjQ?si=i3wdQ5r5Tqu8JUp319Ardg"
                target="_blank"
                rel="noopener noreferrer"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
