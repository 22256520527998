import React, { useEffect, useMemo, useState } from "react";
import Img from "gatsby-image";

const Header = ({ data }) => {
  const [activePopup, setActivePopup] = useState(false);
  return (
    <div className="about section" id="Profile">
      <div className="container">
        <div class="section-head">
          <h2>Profile</h2>
        </div>
        <div className="about-main profile row">
          <div className="left col-md-5 col-lg-4 mb-3">
            <Clickable setActivePopup={setActivePopup}>
            <Img
              fixed={data.bannerImage.fluid}
              objectFit="contain"
              imgStyle={{
                objectFit: "contain"
              }}
              objectPosition="top center"
            />
            </Clickable>
          </div>
          <div className="left col-md-7 col-lg-8">
            <div className="about-details">
              <div style={{ fontSize: "14px" }}>
                <LocalStyles />
                <p>
                  言葉とメロディとアンサンブルを大切するロックバンド。
                  無視される心の弱い部分や、強くあることで生まれるひとりの心に溢れる日々で、僕らが一人にならない為の唄を歌おうと願う。
                  大学のサークルで結成。ホーン隊を加えたバンド「鈴木心&amp;トラベルエア」として、閃光ライオット2013セミファイナルに進出。以降、サークルのセッションや、楽曲制作を通して、人間的・音楽的交流を深めていく。
                  2020年から本格的に活動を開始する。
                </p>
                <ul class="member active">
                  <li>鈴木心</li>
                  <li>ショー・サイトウ</li>
                  <li>伊村邑一朗</li>
                </ul>
                <ul class="member semi-active">
                  <li>黒澤篤志</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {activePopup ? (
          <div className="rg-popup">
            <span
              className="popup-layer"
              onClick={() => setActivePopup(false)}
            ></span>

            <div className="popup-inner">
              <i
                className="fas fa-times"
                onClick={() => setActivePopup(false)}
              ></i>
              <img src={data.bannerImage.fluid.src} alt="popup-img" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const Clickable = ({setActivePopup, children}) => {
  const isClickable = useWindowSize() > 500;
  
  return useMemo(() => {
    if (isClickable) {
      return (
        <a href="#a" onClick={(e) => {e.preventDefault();setActivePopup(true);}}>
          {children}
        </a>
      )  
    } else return <>{children}</>
  }, [isClickable]);
} 

function useWindowSize() {
  const isWindowClient = typeof window === "object";

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? window.innerWidth : undefined
  );

  useEffect(() => {
    //a handler which will be called on change of the screen resize
    function setSize() {
      setWindowSize(window.innerWidth);
    }

    if (isWindowClient) {
      //register the window resize listener
      window.addEventListener("resize", setSize);

      //un-register the listener
      return () => window.removeEventListener("resize", setSize);
    }
  }, [isWindowClient, setWindowSize]);

  return windowSize;
}

export default Header;

const LocalStyles = () => (
  <style
    dangerouslySetInnerHTML={{
      __html: `
      ul.member{
        position: relative;
        padding: 15px 15px 15px 30px;
        font: 14px/1.6 'arial narrow', sans-serif;
        border: solid 2px #717172;
        border-radius:8px;
        color: #34344a;
        max-width:340px;
        width: auto;
        background: #fff;
      }
      ul.member.active:before{
        content: "active";
      }
      ul.member.semi-active:before{
        content: "semi-active";
      }
      ul.member:before{
        position: absolute;
        display: block;
        top: -15px;
        left: 20px;
        background: #fff;
        font-size: 16px;
        font-weight: bold;
        padding: 0 10px;
      }
      `
    }}
  ></style>
);
